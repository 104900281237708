import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import FBMoreIcon from "./assets/FBMoreIcon";
import like from "./assets/like.svg";
import shortNumber from "short-number";

const FacebookPost = ({
  score,
  content,
  title,
  date,
  user,
  subreddit,
  num_comments,
}) => (
  <Container>
    <TopBar>
      <Left>
        <Pfp src={user.icon}></Pfp>
        <Metadata>
          <Title>{subreddit}</Title>
          <SmallMetadata>
            <Username>{user.name}</Username>
            <Dot>·</Dot>
            <Time>{format(new Date(date * 1000), "MMMM dd 'at' h:mm a")}</Time>
          </SmallMetadata>
        </Metadata>
      </Left>
      <Right>
        <FBMoreIcon />
      </Right>
    </TopBar>
    <Content>
      {console.log(title, content)}
      {content.type === "image" && (
        <Content>
          {title}
          <Image src={content.src} />
        </Content>
      )}
      {content.type === "text" && content.src}
      {content.type === "video" && (
        <Content>
          {title}
          <Image src={content.thumbnail} />
        </Content>
      )}
      {content.type === "link" && (
        <Content>
          {title}
          <Link href={content.src}>{content.src}</Link>
        </Content>
      )}
    </Content>
    <BottomBar>
      <BottomBarTop>
        <Left>
          <Like src={like} />
          {shortNumber(score)}
        </Left>
        <Right>{num_comments} Comments</Right>
      </BottomBarTop>
      <BottomBarBottom>
        <BottomItem>
          <LikeBottom />
          Like
        </BottomItem>
        <BottomItem>
          <CommentButton />
          Comment
        </BottomItem>
        <BottomItem>
          <SendButton />
          Send
        </BottomItem>
      </BottomBarBottom>
    </BottomBar>
  </Container>
);

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 24px 16px 24px;
  box-sizing: border-box;
  border-radius: 8px;
  color: #e4e6eb;
  background-color: #242526;
`;

const TopBar = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Pfp = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 3px;
`;

const Metadata = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
`;

const SmallMetadata = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  color: rgb(176, 179, 184);
  font-size: 13px;
  font-weight: bold;
`;

const Time = styled.div`
  color: #b0b3b8;
  font-size: 13px;
`;

const Dot = styled.div`
  margin-left: 3px;
  margin-right: 3px;
`;

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Image = styled.img`
  border-radius: 8px;
  margin-top: 8px;
`;

const Link = styled.a`
  color: rgb(176, 179, 184);
`;

const BottomBar = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const Like = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

const BottomBarTop = styled.div`
  color: rgb(176, 179, 184);
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
`;

const BottomBarBottom = styled.div`
  height: 100%;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: rgb(176, 179, 184);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LikeBottom = styled.div`
  margin-right: 8px;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/_QtvE9sskuw.png);
  background-position: 0px -322px;
  background-size: 25px 816px;
  background-repeat: no-repeat;
  color: rgb(176, 179, 184);
  width: 18px;
  height: 18px;
  filter: invert(62%) sepia(98%) saturate(12%) hue-rotate(175deg)
    brightness(90%) contrast(96%);
`;

const CommentButton = styled.div`
  margin-right: 8px;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/_QtvE9sskuw.png);
  background-position: 0px -284px;
  background-size: 25px 816px;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  filter: invert(62%) sepia(98%) saturate(12%) hue-rotate(175deg)
    brightness(90%) contrast(96%);
`;

const SendButton = styled.div`
  margin-right: 8px;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/_HRbh2mhO6E.png);
  background-position: -50px -86px;
  background-size: 189px 208px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  filter: invert(62%) sepia(98%) saturate(12%) hue-rotate(175deg)
    brightness(90%) contrast(96%);
`;

const BottomItem = styled.div`
  flex: 1;

  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FacebookPost;
