import React from "react";
import styled from "styled-components";
import Facebook from "./Facebook";
import Reddit from "./Reddit";
import FacebookData from "./data/FacebookData";
import RedditData from "./data/RedditData";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

const Controller = () => {
  const [view, setView] = React.useState("Facebook");
  const [dataType, setDataType] = React.useState("Both");

  const getData = () => {
    switch (dataType) {
      case "Reddit":
        return shuffle(RedditData);
      case "Facebook":
        return shuffle(FacebookData);
      default:
        return shuffle([...RedditData, ...FacebookData]);
    }
  };

  return (
    <>
      <TopBar isReddit={view === "Reddit"}>
        View
        <RedditButton type={view} onClick={() => setView("Reddit")}>
          Reddit
        </RedditButton>
        <FacebookButton type={view} onClick={() => setView("Facebook")}>
          Facebook
        </FacebookButton>
        with
        <RedditDataButton
          type={dataType}
          view={view}
          onClick={() => setDataType("Reddit")}
        >
          Reddit
        </RedditDataButton>
        <FacebookDataButton
          type={dataType}
          view={view}
          onClick={() => setDataType("Facebook")}
        >
          Facebook
        </FacebookDataButton>
        <BothDataButton
          type={dataType}
          view={view}
          onClick={() => setDataType("Both")}
        >
          Both
        </BothDataButton>
        data
      </TopBar>
      {view === "Reddit" ? (
        <Reddit data={getData()} />
      ) : (
        <Facebook data={getData()} />
      )}
    </>
  );
};

const TopBar = styled.div`
  height: 36px;
  background-color: ${(props) => (props.isReddit ? "#f0f0f0" : "#242526")};
  color: ${(props) => (props.isReddit ? "black" : "#e4e6eb")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RedditButton = styled.div`
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${(props) => (props.type === "Reddit" ? "default" : "pointer")};
  color: ${(props) => props.type === "Reddit" && "#e4e6eb"};
  background: ${(props) =>
    props.type === "Reddit"
      ? "linear-gradient(140deg, rgba(255,69,0,1) 0%, rgba(255,187,97,1) 100%)"
      : "none"};
  border: ${(props) => (props.type === "Reddit" ? "none" : "1px solid white")};
`;

const FacebookButton = styled.div`
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${(props) => (props.type === "Facebook" ? "default" : "pointer")};
  color: ${(props) => props.type === "Facebook" && "#e4e6eb"};
  background: ${(props) =>
    props.type === "Facebook"
      ? "linear-gradient(140deg, rgba(35,116,225,1) 0%, rgba(255,97,193,1) 100%)"
      : "none"};
  border: ${(props) =>
    props.type === "Facebook" ? "none" : "1px solid black"};
`;

const RedditDataButton = styled.div`
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${(props) => (props.type === "Reddit" ? "default" : "pointer")};
  color: ${(props) => props.type === "Reddit" && "#e4e6eb"};
  background: ${(props) =>
    props.type === "Reddit"
      ? "linear-gradient(140deg, rgba(255,69,0,1) 0%, rgba(255,187,97,1) 100%)"
      : "none"};
  border: ${(props) =>
    props.type === "Reddit"
      ? "none"
      : props.view === "Reddit"
      ? "1px solid black"
      : "1px solid white"};
`;

const FacebookDataButton = styled.div`
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${(props) => (props.type === "Facebook" ? "default" : "pointer")};
  color: ${(props) => props.type === "Facebook" && "#e4e6eb"};
  background: ${(props) =>
    props.type === "Facebook"
      ? "linear-gradient(140deg, rgba(35,116,225,1) 0%, rgba(255,97,193,1) 100%)"
      : "none"};
  border: ${(props) =>
    props.type === "Facebook"
      ? "none"
      : props.view === "Reddit"
      ? "1px solid black"
      : "1px solid white"};
`;

const BothDataButton = styled.div`
  padding: 5px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${(props) => (props.type === "Both" ? "default" : "pointer")};
  color: ${(props) => props.type === "Both" && "#e4e6eb"};
  background: ${(props) =>
    props.type === "Both" ? "rgb(35,116,225)" : "none"};
  background: ${(props) =>
    props.type === "Both"
      ? "linear-gradient(140deg, rgba(35,116,225,1) 0%, rgba(66,109,194,1) 38%, rgba(255,69,0,1) 100%, rgba(255,69,0,1) 100%)"
      : "none"};
  border: ${(props) =>
    props.type === "Both"
      ? "none"
      : props.view === "Reddit"
      ? "1px solid black"
      : "1px solid white"};
`;

export default Controller;
