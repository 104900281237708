const FacebookData = [
  {
    title: "or maybe when they wake up*",
    date: 1658996690.0047522,
    user: {
      name: "Mohammad Junaid",
      icon: "https://source.unsplash.com/100x100/?person",
    },
    subreddit: "Zoom Memes for Self Quaranteens",
    score: 3500,
    num_comments: 193,
    content: {
      type: "image",
      src: "https://i.imgur.com/if7t7cc.jpg",
    },
  },
  {
    title: "Could be us but you playin",
    date: 1659033053.6657712,
    user: {
      name: "John Traj",
      icon: "https://source.unsplash.com/90x90/?person",
    },
    subreddit: "subtle asian dating",
    score: 191,
    num_comments: 110,
    content: {
      type: "image",
      thumbnail: "",
      src: "https://i.imgur.com/YM33OFe.jpg",
    },
  },
  {
    title: "wish me luck",
    date: 1659025339.0021191,
    user: {
      name: "Brianna Lee",
      icon: "https://source.unsplash.com/80x80/?person",
    },
    subreddit: "subtle asian dating",
    score: 211,
    num_comments: 82,
    content: {
      type: "image",
      thumbnail: "",
      src: "https://i.imgur.com/zeqSde0.jpg",
    },
  },
  {
    title: "",
    date: 1659034667.1395156,
    user: {
      name: "Emily Zarnoti",
      icon: "https://source.unsplash.com/101x101/?person",
    },
    subreddit: "subtle asian dating",
    score: 580,
    num_comments: 150,
    content: {
      type: "image",
      thumbnail: "",
      src: "https://i.imgur.com/I9IqymA.jpg",
    },
  },
  {
    title:
      "Okay I need to get something off my chest because this is starting to seriously bug me.",
    date: 1659060832.4472239,
    user: {
      name: "Aine W. McCaul",
      icon: "https://source.unsplash.com/102x102/?person",
    },
    subreddit: "subtle asian traits",
    score: 3600,
    num_comments: 981,
    content: {
      type: "text",
      thumbnail: "",
      src: "Okay I need to get something off my chest because this is starting to seriously bug me. My name is constantly mispronounced by everyone (except one) at my workplace. My Japanese name is Aine - the Ai is pronounced \"I\" as in \"eye 👁\", the Ne is pronounced \"neh\". It's not Ay-nee, Eye-nee, Annie or Anne. I'm fine with \"eye-nay\" if the Neh is too tricky but yeah. Now it's 6 months in at my new workplace. Despite the fact that every staff member's individual walkie-talkie device plays a recording of me saying my name clearly every time I call them (I work in healthcare), they still call me Ay-nee and all sorts of other variations. I've corrected them on occasions to no avail. Like really?🤦🏻‍♀️ I've considered using an English name to make life easier. But this is my name, and my identity. I used to just accept it and let people mispronounce it because I get that it's an unusual name/spelling for English speakers. But the more I think about it, the more it strikes me as just rude, and that they don't want to make an effort to say my name correctly. I mean come on - even people from my online zoom classes can say it right! 🤦🏻‍♀️ As I've said it's already 6 months at the job so I feel silly trying to correct people now 🙁",
    },
  },
  {
    title: "Ummm.. thoughts?",
    date: 1659039924.7807961,
    user: {
      name: "Winnie Nelson",
      icon: "https://source.unsplash.com/103x103/?person",
    },
    subreddit: "subtle asian traits",
    score: 450,
    num_comments: 418,
    content: {
      type: "image",
      thumbnail: "",
      src: "https://i.imgur.com/CckwUgb.jpg",
    },
  },
  {
    title: "plastic bags > photos of her kids ",
    date: 1659060688.7227734,
    user: {
      name: "Ryan Alexander Holmes",
      icon: "https://source.unsplash.com/104x104/?person",
    },
    subreddit: "subtle asian traits",
    score: 324,
    num_comments: 42,
    content: {
      type: "video",
      thumbnail: "https://i.imgur.com/hNOhCv6.jpg",
      src: "https://www.facebook.com/ryanalexh/videos/444286424281781",
    },
  },
  {
    title:
      "Anyone else here have an obsession with Sanrio characters and Gudetama?  ",
    date: 1659066485.5907778,
    user: {
      name: "Angelique Do",
      icon: "https://source.unsplash.com/105x105/?person",
    },
    subreddit: "subtle asian traits",
    score: 712,
    num_comments: 180,
    content: {
      type: "image",
      src: "https://i.imgur.com/w9lTG6B.jpg",
      thumbnail: "",
      others: [
        "https://i.imgur.com/b8t4EEg.jpg",
        "https://i.imgur.com/gf5FTd9.jpg",
        "https://i.imgur.com/omQtnJs.jpg",
      ],
    },
  },
];

export default FacebookData;
