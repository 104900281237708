import * as React from "react";
import styled from "styled-components";
import banner from "./assets/banner.png";
import RedditPost from "./RedditPost";

const TopBarItems = [
  "All",
  "Random",
  "Users",
  "AskReddit",
  "Funny",
  "Worldnews",
  "Pics",
  "Movies",
  "News",
  "Gaming",
  "MILDLYINTERESTING",
  "TWOXCHROMOSOMES",
  "TODAYILEARNED",
];

const Reddit = ({ data }) => {
  return (
    <Container>
      <TopBar>
        <SubredditFont>My Subreddits</SubredditFont>
        <PopularFont>Popular</PopularFont>
        {TopBarItems.map((s) => (
          <SubredditFont>{s}</SubredditFont>
        ))}
      </TopBar>
      <RedditBar>
        <RedditImage src={banner}></RedditImage>
        <SubredditFont style={{ fontWeight: "bold" }}>Popular</SubredditFont>
        <RedditBarTags>hot</RedditBarTags>
        <RedditBarTags>new</RedditBarTags>
        <RedditBarTags>rising</RedditBarTags>
        <RedditBarTags>controversial</RedditBarTags>
        <RedditBarTop>top</RedditBarTop>
        <RedditBarTags>gilded</RedditBarTags>
      </RedditBar>
      <Content>
        <ContentTopBar>
          links from: <AllTime>all time</AllTime>
        </ContentTopBar>
        {data.map((data, i) => (
          <RedditPost {...data} index={i + 1} />
        ))}
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background-color: #ffffff;
  min-height: 100vh;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  background-color: #f0f0f0;
  height: 18px;
  border-bottom: 1px solid black;

  display: flex;
  align-items: center;
`;

const SubredditFont = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  padding-left: 10px;
  white-space: nowrap;
`;

const PopularFont = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  padding-left: 50px;
  color: orangered;
  font-weight: bold;
`;

const RedditBar = styled.div`
  height: 45px;
  background-color: #cee3f8;
  border-bottom: 1px solid #5f99cf;

  display: flex;
  align-items: flex-end;
`;

const RedditImage = styled.img`
  height: 90%;
  padding-bottom: 1px;
`;

const RedditBarTags = styled.div`
  padding: 2px 6px 0 6px;
  background-color: #eff7ff;
  color: #369;
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
`;

const RedditBarTop = styled.div`
  padding: 2px 6px 0 6px;
  background-color: #ffffff;
  border: 1px solid #5f99cf;
  border-bottom: none;
  color: orangered;
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
`;

const Content = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const ContentTopBar = styled.div`
  padding-left: 20px;
  padding-bottom: 5px;
  border-bottom: 1px dotted black;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const AllTime = styled.span`
  padding-left: 3px;
  font-weight: bold;
  text-decoration: underline;
  color: gray;
`;
export default Reddit;
