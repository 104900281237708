const RedditData = [
  {
    title:
      "I've found a few funny memories during lockdown. This is from my 1st tour in 89, backstage in Vegas.",
    date: 1592410647.0,
    user: {
      name: "ReallyRickAstley",
      icon: "https://www.redditstatic.com/avatars/defaults/v2/avatar_default_4.png",
    },
    subreddit: "pics",
    score: 438830,
    num_comments: 19134,
    content: {
      type: "image",
      src: "https://i.redd.it/f58v4g8mwh551.jpg",
    },
  },
  {
    title: "Times Square right now",
    date: 1612029638.0,
    user: {
      name: "SomeGuyInDeutschland",
      icon: "https://styles.redditmedia.com/t5_bcx7b/styles/profileIcon_snoo908b3c30-edde-42cf-b40d-83391a012cb0-headshot-f.png?width=256&height=256&crop=256:256,smart&s=98c80967daec2a3af329ef021215bf076e0456e3",
    },
    subreddit: "wallstreetbets",
    score: 465796,
    num_comments: 13670,
    content: {
      type: "video",
      thumbnail:
        "https://b.thumbs.redditmedia.com/jfKs1Hz6Rp3AQwNSE76uqLoGoyihOAmK4FaBV_Rh6Ek.jpg",
      src: "https://v.redd.it/x64z70f7eie61/DASH_720.mp4",
    },
  },
  {
    title: "Joe Biden elected president of the United States",
    date: 1604766517.0,
    user: {
      name: "throwawaynumber53",
      icon: "https://www.redditstatic.com/avatars/defaults/v2/avatar_default_1.png",
    },
    subreddit: "news",
    score: 365120,
    num_comments: 28625,
    content: {
      type: "link",
      src: "https://apnews.com/article/election-2020-joe-biden-north-america-national-elections-elections-7200c2d4901d8e47f1302954685a737f",
    },
  },
  {
    title:
      'The Senate. Upvote this so that people see it when they Google "The Senate".',
    date: 1491051474.0,
    user: {
      name: "serventofgaben",
      icon: "https://www.redditstatic.com/avatars/defaults/v2/avatar_default_7.png",
    },
    subreddit: "movies",
    score: 404498,
    num_comments: 4987,
    content: {
      type: "image",
      src: "https://i.imgur.com/ChYwfMq.jpg",
    },
  },
  {
    title: "UPVOTE so everyone sees we got SUPPORT",
    date: 1611841234.0,
    user: {
      name: "vrweensy",
      icon: "https://styles.redditmedia.com/t5_2pospk/styles/profileIcon_snoo6e51e2ea-e924-424f-a04b-e387cef45742-headshot.png?width=256&height=256&crop=256:256,smart&s=71a1db69e9f2dc48835c5e05ded579c5717f288d",
    },
    subreddit: "wallstreetbets",
    score: 330864,
    num_comments: 12925,
    content: {
      type: "image",
      src: "https://i.redd.it/sgoqy8nyt2e61.png",
    },
  },
  {
    title:
      "My cab driver tonight was so excited to share with me that he\u2019d made the cover of the calendar. I told him I\u2019d help let the world see",
    date: 1514430055.0,
    user: {
      name: "the_Diva",
      icon: "https://www.redditstatic.com/avatars/defaults/v2/avatar_default_4.png",
    },
    subreddit: "funny",
    score: 308582,
    num_comments: 2465,
    content: {
      type: "image",
      src: "https://i.redd.it/tojcmbvjwk601.jpg",
    },
  },
  {
    title: "A short story",
    date: 1591547255.0,
    user: {
      name: "rextraneous",
      icon: "https://styles.redditmedia.com/t5_2ma2k2/styles/profileIcon_snoo66698b9f-402d-40fb-ad3d-5616d9950143-headshot.png?width=256&height=256&crop=256:256,smart&s=ebf9300cf44d47d4fc5667bf6fcd21a164741106",
    },
    subreddit: "memes",
    score: 365808,
    num_comments: 3756,
    content: {
      type: "image",
      src: "https://i.redd.it/70zdjiilli351.jpg",
    },
  },
  {
    title: "Guardians of the Front Page",
    date: 1480959674.0,
    user: {
      name: "iH8myPP",
      icon: "https://styles.redditmedia.com/t5_p2uyw/styles/profileIcon_snoo892dd3dd-ef43-4706-bae0-2004384b8686-headshot.png?width=256&height=256&crop=256:256,smart&s=ed7279ae900fe3d47436d9e66af079a1ce5c25d7",
    },
    subreddit: "funny",
    score: 283997,
    num_comments: 4966,
    content: {
      type: "video",
      thumbnail:
        "https://b.thumbs.redditmedia.com/ZF37c_fUuPPTootrtYGvCy5vpbcIPT3Feo3uGNNchfE.jpg",
      src: "https://i.imgur.com/OOFRJvr.gif",
    },
  },
  {
    title: "GME YOLO update \u2014 Jan 28 2021",
    date: 1611867983.0,
    user: {
      name: "DeepFuckingValue",
      icon: "https://www.redditstatic.com/avatars/defaults/v2/avatar_default_6.png",
    },
    subreddit: "wallstreetbets",
    score: 293117,
    num_comments: 23318,
    content: {
      type: "image",
      src: "https://i.redd.it/opzucppb15e61.png",
    },
  },
  {
    title:
      "This is what happens when one company owns dozens of local news stations",
    date: 1522529533.0,
    user: {
      name: "patientbearr",
      icon: "https://www.redditstatic.com/avatars/defaults/v2/avatar_default_1.png",
    },
    subreddit: "videos",
    score: 291828,
    num_comments: 11389,
    content: {
      type: "link",
      thumbnail:
        "https://b.thumbs.redditmedia.com/SrWtlQpknnv7s-UIWE7CtcDVObRrYh2LBi1_ZJkIWmM.jpg",
      src: "https://www.youtube.com/watch?v=hWLjYJ4BzvI",
    },
  },
];

export default RedditData;
