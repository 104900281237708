const FBGamingIcon = () => (
  <svg
    viewBox="0 0 28 28"
    class="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 py1f6qlh"
    fill="currentColor"
    height="28"
    width="28"
  >
    <path d="M23.5 9.5H10.25a.75.75 0 00-.75.75v7c0 .414.336.75.75.75H17v5.5H4.5v-19h19v5zm0 14h-5v-6.25a.75.75 0 00-.75-.75H11V11h12.5v12.5zm1.5.25V4.25C25 3.561 24.439 3 23.75 3H4.25C3.561 3 3 3.561 3 4.25v19.5c0 .689.561 1.25 1.25 1.25h19.5c.689 0 1.25-.561 1.25-1.25z"></path>
  </svg>
);

export default FBGamingIcon;
