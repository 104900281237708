const FBMoreIcon = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 20 20"
    width="1em"
    height="1em"
    class="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 py1f6qlh jnigpg78 odw8uiq3"
  >
    <g fill-rule="evenodd" transform="translate(-446 -350)">
      <path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0"></path>
    </g>
  </svg>
);

export default FBMoreIcon;
