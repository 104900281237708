import React from "react";
import styled from "styled-components";
import FBIcon from "./assets/FBIcon";
import FBSearchIcon from "./assets/FBSearchIcon";
import FBHomeIcon from "./assets/FBHomeIcon";
import FBVideoIcon from "./assets/FBVideoIcon";
import FBMarketplaceIcon from "./assets/FBMarketplaceIcon";
import FBGroupIcon from "./assets/FBGroupIcon";
import FBGamingIcon from "./assets/FBGamingIcon";
import FBMenuIcon from "./assets/FBMenuIcon";
import FBMessengerIcon from "./assets/FBMessengerIcon";
import FBNotificationIcon from "./assets/FBNotificationIcon";
import FBLiveIcon from "./assets/FBLiveIcon";
import FBPhotoIcon from "./assets/FBPhotoIcon";
import FBFeelingIcon from "./assets/FBFeelingIcon";
import FacebookPost from "./FacebookPost";

const Facebook = ({ data }) => (
  <Container>
    <TopBar>
      <TopBarLeft>
        <FBIcon />
        <SearchBar>
          <FBSearchIcon />
          <SearchText>Search Facebook</SearchText>
        </SearchBar>
      </TopBarLeft>
      <TopBarMid>
        <MidItem
          style={{ color: "#2374E1", borderBottom: "3px solid #2374E1" }}
        >
          <FBHomeIcon />
        </MidItem>
        <MidItem>
          <FBVideoIcon />
        </MidItem>
        <MidItem>
          <FBMarketplaceIcon />
        </MidItem>
        <MidItem>
          <FBGroupIcon />
        </MidItem>
        <MidItem>
          <FBGamingIcon />
        </MidItem>
      </TopBarMid>
      <TopBarRight>
        <RightItem>
          <FBMenuIcon />
        </RightItem>
        <RightItem>
          <FBMessengerIcon />
        </RightItem>
        <RightItem>
          <FBNotificationIcon />
        </RightItem>
        <RightItem>
          <Pfp
            src="https://upload.wikimedia.org/wikipedia/en/thumb/6/6e/University_of_Waterloo_seal.svg/1920px-University_of_Waterloo_seal.svg.png"
            alt="pfp"
          />
        </RightItem>
      </TopBarRight>
    </TopBar>
    <ContentPane>
      <Content>
        <StoryCarousel>
          <Story>
            <StoryIcon />
          </Story>
          <Story>
            <StoryIcon />
          </Story>
          <Story>
            <StoryIcon />
          </Story>
          <Story>
            <StoryIcon />
          </Story>
          <Story>
            <StoryIcon />
          </Story>
        </StoryCarousel>
        <StatusBar>
          <StatusTop>
            <RightItem>
              <Pfp
                src="https://upload.wikimedia.org/wikipedia/en/thumb/6/6e/University_of_Waterloo_seal.svg/1920px-University_of_Waterloo_seal.svg.png"
                alt="pfp"
              />
            </RightItem>
            <SearchBar style={{ width: "100%" }}>
              <SearchText>What's on your mind?</SearchText>
            </SearchBar>
          </StatusTop>
          <StatusBottom>
            <StatusBottomItem>
              <Live>
                <FBLiveIcon />
              </Live>
              Live video
            </StatusBottomItem>
            <StatusBottomItem>
              <Photo>
                <FBPhotoIcon />
              </Photo>
              Photo/video
            </StatusBottomItem>
            <StatusBottomItem>
              <Feeling>
                <FBFeelingIcon />
              </Feeling>
              Feeling/activity
            </StatusBottomItem>
          </StatusBottom>
        </StatusBar>
        <Posts>
          {data.map((data) => (
            <FacebookPost {...data} />
          ))}
        </Posts>
      </Content>
    </ContentPane>
  </Container>
);

const Container = styled.div`
  background-color: #1c1e21;
  min-height: 100vh;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  background-color: #242526;
  height: 56px;
  border-bottom: 1px solid #393a3b;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchBar = styled.div`
  margin-left: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 240px;
  height: 40px;
  border-radius: 40px;
  background-color: #3a3b3c;
  color: #b0b3b8;

  display: flex;
  align-items: center;
`;

const SearchText = styled.div`
  padding-left: 10px;
`;

const TopBarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopBarMid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MidItem = styled.div`
  height: 55px;
  width: 100px;
  color: #b0b3b8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopBarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightItem = styled.div`
  width: 40px;
  height: 40px;
  margin: 4px;
  border-radius: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Pfp = styled.img`
  width: 40px;
  height: 40px;
`;

const ContentPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 800px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StoryCarousel = styled.div`
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Story = styled.div`
  flex: 1;
  height: 230px;
  margin: 5px;
  border-radius: 8px;
  background-color: #303031;
`;

const StoryIcon = styled.div`
  margin: 12px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #454546;
`;

const StatusBar = styled.div`
  width: 100%;
  height: 120px;
  padding: 12px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #242526;
  display: flex;
  flex-direction: column;
`;

const StatusTop = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusBottom = styled.div`
  height: 100%;
  padding-top: 8px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusBottomItem = styled.div`
  flex: 1;
  color: rgb(176, 179, 184);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Live = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #f3425f;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Photo = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #45bd62;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Feeling = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #f7b928;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Posts = styled.div`
  width: 100%;
`;

export default Facebook;
