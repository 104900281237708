import React from "react";
import styled from "styled-components";
import shortNumber from "short-number";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

const RedditPost = ({
  index,
  score,
  content,
  title,
  date,
  user,
  subreddit,
  num_comments,
}) => (
  <Container>
    <Index>{index}</Index>
    <Score>
      <UpArrow />
      {shortNumber(score)}
      <DownArrow />
    </Score>
    <Thumbnail>
      {content.type === "image" && <ImageThumbnail src={content.src} />}
      {content.type === "video" && <ImageThumbnail src={content.thumbnail} />}
      {content.type === "link" &&
        (content.thumbnail !== undefined ? (
          <ImageThumbnail src={content.thumbnail} />
        ) : (
          <LinkThumbnail />
        ))}
      {content.type === "text" && <TextThumbnail />}
    </Thumbnail>
    <Metadata>
      <Title href={content.src}>{title || "[No Title]"}</Title>
      <Meta>
        submitted {timeAgo.format(new Date(date * 1000), "round")} by{" "}
        <Blue>{user.name}</Blue> to <Blue>r/{subreddit}</Blue>
      </Meta>
      <InteractionsBar>
        <Interaction>{num_comments} comments</Interaction>
        <Interaction>share</Interaction>
        <Interaction>save</Interaction>
        <Interaction>hide</Interaction>
        <Interaction style={{ color: "#a79128" }}>give award</Interaction>
        <Interaction>report</Interaction>
        <Interaction>crosspost</Interaction>
      </InteractionsBar>
    </Metadata>
  </Container>
);

const Container = styled.div`
  padding: 10px;
  display: flex;
`;

const Index = styled.div`
  width: 20px;
  color: #c6c6c6;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Score = styled.div`
  width: 50px;
  margin: 0 7px 0 7px;
  color: #c6c6c6;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const UpArrow = styled.div`
  height: 14px;
  width: 15px;
  background-image: url(https://www.redditstatic.com/sprite-reddit.5kxTB7FXse0.png);
  background-position: -106px -1654px;
  background-repeat: no-repeat;
`;

const DownArrow = styled.div`
  height: 14px;
  width: 15px;
  background-image: url(https://www.redditstatic.com/sprite-reddit.5kxTB7FXse0.png);
  background-position: -64px -1654px;
  background-repeat: no-repeat;
`;

const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageThumbnail = styled.img`
  height: 50px;
  width: 70px;
`;

const LinkThumbnail = styled.div`
  height: 50px;
  width: 70px;
  background-image: url(https://www.redditstatic.com/sprite-reddit.5kxTB7FXse0.png);
  background-position: 0px -229px;
  background-repeat: no-repeat;
  background-size: 70px 864px;
`;

const TextThumbnail = styled.div`
  height: 50px;
  width: 70px;
  background-image: url(https://www.redditstatic.com/sprite-reddit.5kxTB7FXse0.png);
  background-position: 0px -448px;
  background-repeat: no-repeat;
  background-size: 70px 864px;
`;

const Metadata = styled.div`
  flex: 1;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.a`
  font-size: 16px;
  text-decoration: none;
`;

const Meta = styled.div`
  padding-top: 3px;
  font-size: 10px;
  color: #888;
`;

const Blue = styled.span`
  color: #369;
`;

const InteractionsBar = styled.div`
  padding-top: 3px;
  display: flex;
`;

const Interaction = styled.div`
  margin-right: 8px;
  color: #888;
  font-size: 10px;
  font-weight: bold;
`;

export default RedditPost;
