const FBMenuIcon = () => (
  <svg
    fill="currentColor"
    viewBox="0 0 44 44"
    width="1em"
    height="1em"
    class="a8c37x1j ms05siws l3qrxjdp b7h9ocf4 rs22bh7c jnigpg78 odw8uiq3"
  >
    <circle cx="7" cy="7" r="6"></circle>
    <circle cx="22" cy="7" r="6"></circle>
    <circle cx="37" cy="7" r="6"></circle>
    <circle cx="7" cy="22" r="6"></circle>
    <circle cx="22" cy="22" r="6"></circle>
    <circle cx="37" cy="22" r="6"></circle>
    <circle cx="7" cy="37" r="6"></circle>
    <circle cx="22" cy="37" r="6"></circle>
    <circle cx="37" cy="37" r="6"></circle>
  </svg>
);

export default FBMenuIcon;
